//import { Bootstrap } from '@/';
//import VueGoogleMaps from ';
import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import MockAdapter from "@/core/mock/MockService";
import ApiService from "@/core/services/ApiService";
import MockAPIService from "@/core/services/MockAPIService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import 'bootstrap';
import VueGoogleMaps from "@fawmi/vue-google-maps";
import mitt from 'mitt'
import {DxSelectBox} from "devextreme-vue"
import 'devextreme/dist/css/dx.light.css';

const emitter = mitt()
const app = createApp(App);


app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBLzibLmKvxwZauzN8DA245eVyaHqtY1cI",
    libraries: "visualization",
  },
});


app.component('DxSelectBox', DxSelectBox);

app.provide('emitter', emitter);   
app.use(store);
app.use(router);
app.use(ElementPlus);



ApiService.init(app);
MockAPIService.init(app);
MockAdapter.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
